<template>
  <h1>Logout</h1>
</template>

<script>
export default {
  name: "Logout"
};
</script>

<style></style>
